import React from "react"
import { Link } from "gatsby"

import styles from "./language-switcher.module.css"

const LanguageSwitcher = ({ children, minWidth }) => (
  <div className={styles.wrapper}>
    <Link className={styles.a} to="/en">EN</Link>&nbsp;&nbsp;<Link className={styles.a} to="/">FR</Link>
  </div>
)

export default LanguageSwitcher
