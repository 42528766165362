/* hipster style
const compose = require('lodash/fp/compose');
const pick = require('lodash/fp/pick');
const flatten = require('lodash/fp/flatten');
const values = require('lodash/fp/values');
const compact = require('lodash/fp/compact');

const getLocale = () =>
    compose(
          compact,
          flatten,
          values,
          pick(browserLanguagePropertyKeys)
        )(window.navigator)
    .map(s => s.substr(0, 2))
    .find(s => languages.includes(s)) || 'en';

useful to support ie11 etc?
const browserLanguagePropertyKeys = [
    'languages',
    'language',
    'browserLanguage',
    'userLanguage',
    'systemLanguage'
];
*/

const catalogEN = require('./locales/en/messages');
const catalogFR = require('./locales/fr/messages');

const languages = ['fr', 'en'];

const catalogs = {
    en: catalogEN,
    fr: catalogFR
};

const defaultLanguage = 'fr';

const langPrefix = lang => (lang === defaultLanguage ? '' : `/${lang}`);
const deprefix = pathname =>
    pathname[0] === '/' && pathname[3] === '/' ? pathname.substr(3) : pathname;
const langFromPath = pathname => {
    const lang = pathname.split('/')[1];
    return languages.includes(lang) ? lang : 'fr';
};


const getLocale = () => window.navigator.language || 'fr';

exports.defaultLanguage = defaultLanguage;
exports.languages = languages;
exports.catalogs = catalogs;
exports.langPrefix = langPrefix;
exports.deprefix = deprefix;
exports.langFromPath = langFromPath;
exports.getLocale = getLocale;
