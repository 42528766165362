/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { I18nProvider } from "@lingui/react"
import { Trans } from "@lingui/macro"

import { catalogs } from "../i18n-config"
import LanguageSwitcher from "../components/language-switcher"
import "./layout.css"

import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

const Layout = ( props ) => {
  const lang = props.pageContext ? props.pageContext.lang : 'fr'
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <I18nProvider language={lang} catalogs={catalogs}>
          <div>
            <main style={{ position: `relative`}}>{props.children}</main>
            <LanguageSwitcher />
            <footer style={{ 
              textAlign: `center`, 
              color: `#484848`, 
              padding: `20px`, 
              fontSize: `10px` 
            }}>
              © {new Date().getFullYear()}, 
              <Trans>All rights reserved.</Trans>
            </footer>
          </div>
        </I18nProvider>
      )}
    />
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
